/* benificiary.module.css */

.container {
    padding: 20px;
    /* max-width: 800px; */
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
    /* background-color: red; */
}

.title {
    margin-bottom: 20px;
}

.formContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    /* background-color: red; */
}

.dropdownContainer {
    flex: 1;
    margin-right: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #ddd; 
    border-radius: 4px;
    padding: 5px; 
}

.dropdownContainer select {
    border: 1px solid #ddd;
    border-radius: 4px; 
    padding: 5px;
    margin-left: 10px; 
    flex: 1;
}

.label {
    margin-right: 10px;
}

.radiusControls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.radiusLabel {
    margin-right: 10px;
}

.radiusInput {
    width: 80px;
    text-align: center;
    border: 1px solid #ddd; 
    border-radius: 4px; 
    padding: 5px;
}

.tableContainer {
    margin-top: 20px;
}
.absence{
    text-align: center;
}

table {
    width: 100%;
}

th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

thead {
    background-color: #f4f4f4;
}


.searchContainer {
    display: flex;
    align-items: center;
  
    gap: 10px;
}


.searchButton {
    padding: 0.5rem 1rem;
    background-color: rgb(31, 209, 81);
    color: white;
    border: none;
    margin-left: 3rem;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
}

.clearButton{
    padding: 0.5rem 1rem;
    background-color: rgb(31, 209, 81);
    color: white;
    border: none;
    margin-left: 3rem;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;

}

.paginationContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.paginationControls {
    display: flex;
    gap: 5px;
}

.pageButton {
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
}

.activePage {
    background-color: rgb(31, 209, 81);
    color: #fff;
    border-color: rgb(31, 209, 81);
}

.pageButton:hover {
    background-color: #e2e6ea;
}

.pageButton:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
    border-color: #dee2e6;
}


