.mainContainer {
    width: calc(100% - 100px);
    position: absolute;
    left: 100px;
    top: 0;
    height: 100vh;
    margin-top: 20px;
}

.tableContainer {
    margin-top: 20px;
    padding-right: 20px;
    height: 76vh;
    overflow: scroll;
    overflow-x: scroll;
    font-size: 14px;
}