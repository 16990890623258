.center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.customBtnStyle{
    border: 1px solid white !important;
    background-color: green !important;
    padding: .8rem !important;
    color: white !important;
    border-radius: 1rem !important;
}

.disabled{
    background-color: rgb(209, 209, 209) !important;
    padding: .8rem !important;
    /* border: 5px solid red !important; */
}

.deleteIcon{
    color: red;
    cursor: pointer;
   width: 100% ;
    
}

.button_align{
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
.deleteIconDisabled{
    color:rgb(209, 209, 209) !important;
    cursor: pointer;
    width: 100% ;
}