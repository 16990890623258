.flex{
    display: flex;
    flex-direction: column;
}
.card_Wrapper{
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border-radius: 1rem;
    padding: 1rem;
}
.card_heading{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
}
.temp_Variable{
 counter-reset: css-counter 0; 
}

.temp_Variable p{
    counter-increment: css-counter 1;
}
.temp_Variable p:before{
    content: counter(css-counter) ". ";
}