.main_div{
    
    box-sizing: border-box;
   
   
}
.heading{
    text-align: center;
}

.add_content_div{
    display: flex;
    margin-right: 20px;
    gap: 10px;
}