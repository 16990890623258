.main {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-bottom: 30px;
    padding: 20;
}

.printBtnDiv {
    width: 50px;
    margin: auto;
    margin-bottom: 10px;
}

.stickerBox {
    margin: auto;
    margin-top: 10px;
    /* border: 1px solid red; */
}

.nameContainer {
    text-align: center;
    font-weight: 500;
    max-height: 50px;
    /* white-space: nowrap; */
    /* overflow: hidden; */
}

.addressContainer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.addressContainer>p {
    font-weight: bold;
    text-align: left;
}

.sticker {
    width: 100%;
    max-width: 220px;
    min-width: 220px;
    min-height: 330px;
    max-height: 350;
    align-items: center;
    border: 1px solid black;
    box-sizing: border-box;
}

.logoContainer {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hr {
    background: linear-gradient(to right,
            rgb(255, 38, 34),
            rgb(0, 60, 255),
            rgba(2, 242, 14, 0.885));
    height: 1px;
}

.upperTextContainer {
    padding: 2px;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    color: teal;
}

.qrContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.qrContainer>div {
    max-width: 95%;
}

.phoneContainer {
    text-align: center;
    font-weight: 600;
    /* margin-bottom: 1.2rem; */
    color: red;
}

.footerContainer {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.controllerDiv {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 20px;
}

.multiselect {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.downloadBtn {
    width: 70%;
    margin: auto;
    margin-bottom: 10px;
}

.pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid black;
}

.printBtn {
    text-align: center;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .main {
        grid-template-columns: repeat(2, 1fr);
    }

    .sticker {
        max-width: 100%;
        min-width: 100%;
    }
}