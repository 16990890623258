.mainContainer{
    width: calc(100% - 100px);
    position: absolute;
    left: 100px;
    top: 0;
    height: 100vh;   
}
.flex{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}
.button{
    border: 1px solid white;
    background-color: green;
    padding: .5rem;
    color: white;
    border-radius: 1rem;
}

.customBtnStyle{
    border: 1px solid white !important;
    background-color: green !important;
    padding: .8rem !important;
    color: white !important;
    border-radius: 1rem !important;
}

.child{
    width: 100%;
    /* border: 1px solid red; */
}
.child input{
    width: 100%; 
    border: 1px solid black;
    border-radius: 0.5rem;
    height: 2rem;
    margin-bottom: .3rem;
    padding: 1rem;

}
.center{
    margin-top: 1rem;
}
