
.mainContainer {
  
    width: calc(100% - 100px);
    position: absolute;
    left: 100px;
    top: 0;
    height: 100vh;
    /* border: 1px solid red; */

}

.grid{
   
    display: grid;
    grid-template-columns: repeat(5,1fr);
    /* border: 1px solid red; */
    margin-top: 1rem;
    align-items:flex-end ;
    width: 80%;

}
.button{
    background-color: rgb(31, 209, 81);
    padding: .5rem 1rem;
    color: white;
    border-radius: .5rem;
}
.button:active{
    transform: scale(0.95);
}
.margin_Top{
    margin-top: 1rem;
}
.flex_Search{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;  
    /* height: 100px; */
      /* border: 1px solid red; */
}
.flex_filter{
    display: flex;
    align-items: flex-end;
    width: 70%;
    justify-content: space-between;
    margin-top: 1rem;
}
.wrapperTable{
    margin-top: 1rem;
    /* border: 1px solid red; */
    overflow: scroll;
}
.flex{
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* border: 1px solid red; */
    gap: 1rem;
}
.felx_btn{
    display: flex;
    justify-content: space-between;
    width: 60%;
}
.loadingStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:500px;
    /* border: 1px solid red; */
}
.gap{
    margin-top: 1rem;
}

