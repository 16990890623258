.wrapper label{
    display: flex;
    align-items: center;
    gap: .3rem;
    /* border: 1px solid red; */
    width: 100%;
    font-size: 1rem;
    position: relative; 
    /* color: #34db37; */
}
.inputStyle{
    width: 1rem;
    height: 1rem;
 
    border-radius: .2rem;
    border: 2px solid #3498db; /* Border color for unchecked checkbox */   
    outline: none; /* Remove focus outline */
    cursor: pointer; /* S*/
}

  



