.animated_background{
    
    border: 2px solid transparent;
    position: relative;
    border-image-source: linear-gradient(90deg, rgb(234, 181, 181), blue, green, yellow, red);
    border-image-slice: 1;
    border-image-width: 2px;
    border-image-repeat: round;
    padding: 20px;
    font-size: 18px;
}

