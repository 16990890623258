
.button{
    border: 1px solid white;
    background-color: aqua;
    padding: .5rem;
    color: white;
    border-radius: 1rem;
}

.disabled{
    background-color: rgb(209, 209, 209) !important;
    border: 5px solid red !important;
}