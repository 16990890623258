.table_container{
    /* border: 1px solid red; */
   overflow: scroll; 
   
}

.tableHeader{
  position: sticky;
  background: '#fff';
  z-index: 1;
  top: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
   /* Adjust the margin based on your design */
   cursor: pointer;
  }
  .address{
    display: flex;
    flex-direction: column;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
  /* Responsive styling */
  @media only screen and (max-width: 600px) {
    table, th, td {
      font-size: 14px; /* Adjust font size for small screens */
    }
  }